import webui from "@tinqin/tinqin-web-ui";
const { getEntityChildren, getEntityValue } = webui.getActionByName("utils");

const MAIN_MENU_PATH = "header.links";
const MAIN_MENU_PF_PATH = "header.headerLinks";
const MAIN_MENU_SELECTOR = ".un-header-links ul > li";
const CLASS_NAME = "un-current-page";

//Toggle main menu className depending on switchContainer action articleId
export const toggleClassMainMenuItem = (sirenAction, widgetId, currentPath) => {
    return (dispatch, getState) => {
        const menuItems =
            dispatch(getEntityChildren(MAIN_MENU_PATH)) ||
            dispatch(getEntityChildren(MAIN_MENU_PF_PATH));
        let currentPage = sirenAction?.properties?.value;
        //Action is called from the header and need to get the articleId from the inner widget
        if (!currentPage) {
            const data = getState()
                .getIn(["data"])
                .toJS();
            Object.keys(data).some(widget => {
                const value =
                    dispatch(getEntityValue(`${widget}::landing.meta.articleId`)) ||
                    dispatch(getEntityValue(`${widget}::marketplaceOffers.meta.articleId`));
                if (value) {
                    currentPage = value;
                }
                return !!value;
            });
        }
        if (currentPage) {
            const currentMenuItemIndex = menuItems.findIndex(item =>
                item?.actions.find(
                    action =>
                        action.actionType === "switchContainer" &&
                        action?.parameters?.articleId === currentPage
                )
            );
            if (currentMenuItemIndex !== -1) {
                setTimeout(() => {
                    const elements = document.querySelectorAll(MAIN_MENU_SELECTOR);
                    const type = sirenAction?.properties?.type;
                    for (let i = 0; i < elements.length; i++) {
                        if (type) {
                            elements[i].classList[type](CLASS_NAME);
                        } else {
                            elements[i].classList[i === currentMenuItemIndex ? "add" : "remove"](
                                CLASS_NAME
                            );
                        }
                    }
                }, 500);
            }
        }
    };
};
