window.tarteaucitronConsents = {};
const tarteaucitron = window.tarteaucitron || { services: {} };
// const giveConsent = (key, accepted) => {
//     window.tarteaucitronConsents[key] = {
//         accepted
//     };
//     const event = new CustomEvent("tarteaucitronConsentChange", {
//         detail: window.tarteaucitronConsents
//     });
//     document.dispatchEvent(event);
// };

//todo: review services ; populate data from settings to tarteaucitron user

// youtube
tarteaucitron.services.youtube = {
    key: "youtube",
    type: "video",
    name: "YouTube",
    uri: "https://policies.google.com/privacy",
    needConsent: true,
    cookies: ["VISITOR_INFO1_LIVE", "YSC", "PREF", "GEUP"],
    text: tarteaucitron.lang["engage-addtoanyshare"],
    js: function() {},
    fallback: function() {}
};

function setupMatomo() {
    //setup MatomoConfig - other matomo setup is done by tarteaucitron
    let matomoConfig;
    const isWebCrawler = window.navigator.userAgent.includes("Prerender");
    if (window.globalSettings.matomo && !isWebCrawler) {
        matomoConfig = window.globalSettings.matomo;
        if (matomoConfig.selfHosted) {
            let matomoUrl = matomoConfig.url || "";
            if (matomoUrl.length && matomoUrl[matomoUrl.length - 1] !== "/") {
                matomoUrl += "/";
            }
            matomoConfig.url = matomoUrl;
        }
    }
    tarteaucitron.user.matomoId = matomoConfig.siteId || 1;
    tarteaucitron.user.matomoContainer = matomoConfig.container;

    tarteaucitron.user.matomoHost = matomoConfig.url;
    tarteaucitron.user.matomoTagManager = true;
}
setupMatomo();

tarteaucitron.services.matomo = {
    key: "matomo",
    type: "analytic",
    name: "Matomo (précédemment connu comme Piwik)",
    uri: "https://matomo.org/faq/general/faq_146/",
    needConsent: true,
    cookies: [
        "_pk_ref",
        "_pk_cvar",
        "_pk_id",
        "_pk_ses",
        "_pk_hsr",
        "mtm_consent",
        "matomo_ignore",
        "matomo_sessid"
    ],
    text: tarteaucitron.lang["engage-addtoanyfeed"],
    js: function() {
        if (tarteaucitron.user.matomoId === undefined) {
            return;
        }

        window._paq = window._paq || [];
        window._paq.push(["setDoNotTrack", true]);
        window._paq.push(["disableCookies"]);
        window._paq.push(["enableLinkTracking"]);
        window._paq.push([
            "setTrackerUrl",
            "https://" + tarteaucitron.user.matomoHost + "/matomo.php"
        ]);
        window._paq.push(["setSiteId", tarteaucitron.user.matomoId]);
        window._paq.push(["HeatmapSessionRecording::disableAutoDetectNewPageView"]);
        window._paq.push([
            function() {
                var self = this;
                function getOriginalVisitorCookieTimeout() {
                    var now = new Date(),
                        nowTs = Math.round(now.getTime() / 1000),
                        visitorInfo = self.getVisitorInfo();
                    var createTs = parseInt(visitorInfo[2]);
                    var cookieTimeout = 33696000; // 13 mois en secondes
                    var originalTimeout = createTs + cookieTimeout - nowTs;
                    return originalTimeout;
                }
                this.setVisitorCookieTimeout(getOriginalVisitorCookieTimeout());
            }
        ]);
        tarteaucitron.addScript(
            `https://cdn.matomo.cloud/${tarteaucitron.user.matomoHost}/matomo.js`,
            "",
            "",
            true,
            "defer",
            false
        );
        //Matomo tag manager
        if (tarteaucitron.user.matomoTagManager) {
            window._mtm = window._mtm || [];
            window._mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
            tarteaucitron.addScript(
                `https://cdn.matomo.cloud/${tarteaucitron.user.matomoHost}/${tarteaucitron.user.matomoContainer}.js`,
                "",
                "",
                true,
                "defer",
                false
            );
        }
        //End matomo tag manager

        // waiting for Matomo to be ready to check first party cookies
        var interval = setInterval(function() {
            if (typeof window.Matomo === "undefined") return;

            clearInterval(interval);

            // make Matomo cookie accessible by getting tracker
            window.Matomo.getTracker();

            // looping through cookies
            var theCookies = document.cookie.split(";");
            for (var i = 1; i <= theCookies.length; i++) {
                var cookie = theCookies[i - 1].split("=");
                var cookieName = cookie[0].trim();

                // if cookie starts like a matomo one, register it
                if (cookieName.indexOf("_pk_") === 0) {
                    tarteaucitron.services.matomo.cookies.push(cookieName);
                }
            }
        }, 100);
    }
};

// google maps
tarteaucitron.services.googlemaps = {
    key: "googlemaps",
    type: "api",
    name: "Google Maps",
    uri: "https://adssettings.google.com/",
    needConsent: true,
    cookies: [],
    text: tarteaucitron.lang["engage-addthis"],
    js: function() {},
    fallback: function() {}
};
