let tarteaucitron;
export function load(settings) {
    import("./tarteaucitron.20230203.css").then(() => {
        const tarteaucitronScript = document.createElement("script");
        tarteaucitronScript.src = `https://tarteaucitron.io/load.js?locale=fr&domain=${settings.domain}&uuid=${settings.uuid}`;
        tarteaucitronScript.addEventListener("load", function(event) {
            tarteaucitron = window.tarteaucitron;
            tarteaucitron.addScript(
                `${tarteaucitron.cdn}lang/tarteaucitron.${tarteaucitron.getLanguage()}.js?v=${
                    tarteaucitron.version
                }`,
                "fr-lang",
                () => {
                    if (
                        document.readyState === "complete" &&
                        window.alreadyLaunch === 1 &&
                        !document.getElementById("tarteaucitronServices")
                    ) {
                        if (window.addEventListener) {
                            tarteaucitron.initEvents.loadEvent(false);
                        } else {
                            tarteaucitron.initEvents.loadEvent(true);
                        }
                    }

                    afterLoad(settings);
                }
            );
        });
        document.head.append(tarteaucitronScript);
    });
}

function afterLoad(settings) {
    const afterRenderInterval = setInterval(() => {
        const tarteaucitronServicesDiv = document.getElementById("tarteaucitronServices");
        const translationsArrived = tarteaucitron.lang["engage-addthis"];
        if (tarteaucitronServicesDiv && afterRenderInterval && translationsArrived) {
            clearInterval(afterRenderInterval);
            require("./tarteaucitron.services.js");
            Object.keys(tarteaucitron.services).forEach(service => {
                tarteaucitron.job.push(tarteaucitron.services[service].key);
            });
            attachFetch(settings);
            removeExtraElements();
            replaceElements();
            addElements();
            fixGroupedLines();
            addClickEvents();
        }
    }, 30);
}
function fixGroupedLines() {
    const serviceDiv = document.getElementById("tarteaucitronServices");
    serviceDiv.querySelectorAll('[id$="Line"]').forEach(el => {
        const key = el.id.replace("Line", "");
        const anchor = el.querySelector("a.tarteaucitronReadmoreInfo");
        anchor.href += `#${key}`;
    });
}
function addClickEvents() {
    tarteaucitron.addClickEventToId("tarteaucitronAllDenied", function() {
        tarteaucitron.userInterface.closePanel();
        setTimeout(() => {
            if (tarteaucitron.reloadThePage === true) {
                window.location.reload();
            }
        }, 50);
    });
    tarteaucitron.addClickEventToId("tarteaucitronAllAllowed", function() {
        tarteaucitron.userInterface.closePanel();
    });

    // tarteaucitron.addClickEventToId("tarteaucitronPersonalize2", function () {
    //     tarteaucitron.userInterface.closeAlert();
    // });
    // tarteaucitron.addClickEventToId("tarteaucitronAllDenied2", function () {
    //     tarteaucitron.userInterface.closeAlert();
    // });
}

function replaceElements() {
    let el1, el2;
    // swap accept all and personalize buttons in first time modal
    el1 = document.getElementById("tarteaucitronPersonalize2");
    el2 = document.getElementById("tarteaucitronCloseAlert");
    insertAfter(el1, el2);

    // swap accept all and deny all buttons
    el1 = document.getElementById("tarteaucitronAllAllowed");
    el2 = document.getElementById("tarteaucitronAllDenied");
    insertAfter(el1, el2);

    // swap allow and deny buttons
    Array.from(document.querySelectorAll("li.tarteaucitronLine .tarteaucitronAsk")).forEach(
        line => {
            el1 = line.children[0];
            el2 = line.children[1];
            insertAfter(el1, el2);
        }
    );
}

function removeExtraElements() {
    document
        .querySelectorAll("#tarteaucitronServices ul li .tarteaucitronTitle")
        .forEach(i => i.remove());
    document
        .querySelector("#tarteaucitronServices .tarteaucitronBorder ul")
        .querySelectorAll("button.tarteaucitron-toggle-group")
        .forEach(i => (i.innerText = ""));
    document
        .querySelectorAll(".tarteaucitronName .tarteaucitronReadmoreSeparator")
        .forEach(i => i.remove());
    document.getElementById("tarteaucitronBack").title = "";

    //remove mandatory section
    document.getElementById("tarteaucitronServicesTitle_mandatory").remove();
}

function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function attachFetch(settings) {
    const respond = tarteaucitron.userInterface.respond;
    let lastElement;
    const updateUrl = settings.updateUrl;
    window.tarteaucitron.userInterface.respond = function() {
        respond(arguments[0], arguments[1]);
        if (arguments[0] !== lastElement && updateUrl) {
            fetch(updateUrl, {
                method: "post",
                mode: "no-cors",
                credentials: "include"
            }).then();
            lastElement = arguments[0];
        }
    };
}

function addElements() {
    Object.keys(tarteaucitron.services).forEach(service => {
        const statusDiv = document.querySelector(`#${service}Line .tarteaucitronStatusInfo`);
        const textContent = document.createElement("p");
        textContent.innerHTML = tarteaucitron.services[service].text;
        insertAfter(textContent, statusDiv);
    });
}
